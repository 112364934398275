import { API } from './api';

function checkRegcode(code) {
  return API.check_regcode(code);
}

function checkUsername(username, password) {
  return API.check_username(username, password);
}

function resetPassword(payload) {
  return API.reset_password(payload);
}

function changePasswordWithToken(payload) {
  return API.changePasswordWithToken(payload);
}

async function login(email, pw) {
  let loginResult, recordLoginResult;

  try {
    loginResult = await API.login(email, pw);
  } catch (e) {
    console.log('====> error with login call', e);
  }

  await API.wrap_output();

  try {
    recordLoginResult = await API.record_login();
  } catch (e) {
    console.log('====> error with record_login call', e);
  }

  console.log('====> login', loginResult);

  return loginResult;
  /*
  return API.login(email, pw)
    .then(res => {
      return API.wrap_output()
        .then(() => {
          return API.record_login()
            .then(() => {
              return res;
            });
        });
    }, err => {
      throw err;
    });
    */
}

function pushConcurrent(user_id, session_id) {
	return API.push_concurrent(user_id, session_id);
}

function routeToInterface() {
  return API.route_to_interface();
}
  
function getSchoolInfoById(school_id) {
  return API.get_school_info_by_id(school_id); 
}

function checkUsernameOrEmail(payload) {
  return API.check_username_or_email(payload);
}

function checkSecurityQuestions(payload) {
console.log('checkSecurityQuestions', payload);
  return API.check_security_questions(payload);
}

function setSecurityQuestions(payload) {
  return API.set_security_questions(payload);
}

function userCreate(payload) {
  return API.user_create(payload);
}

function addToAccount(payload) {
  return API.add_to_account(payload);
}

function resetStudentPassword(payload) {
  return API.reset_student_password(payload);
}

function getTrialCode(type) {
  return API.wrap_output()
    .then(res => {
      return API.get_trial_code(type);
    });
}

function getTrialInfo() {
  return API.get_trial_info();
}

function signalConcurrent(status) {
  var { user_id, concurrent_session_ids } = status;
  if (Array.isArray(concurrent_session_ids)) {
    concurrent_session_ids.forEach(sess_id => {
      console.log('Log out session ID', user_id, sess_id);
      pushConcurrent(user_id, sess_id);
    });
  }
}

async function validCredentialsRouteToLandingPage(data) {
  const res = await routeToInterface();
  
  window.location.href = res?.url;
}

export { 
  checkSecurityQuestions, 
  setSecurityQuestions, 
  resetStudentPassword, 
  checkRegcode, 
  checkUsername, 
  resetPassword, 
  changePasswordWithToken, 
  login, 
  pushConcurrent, 
  routeToInterface, 
  getSchoolInfoById, 
  checkUsernameOrEmail, 
  userCreate, 
  addToAccount, 
  getTrialCode, 
  getTrialInfo,
  signalConcurrent,
  validCredentialsRouteToLandingPage,
};


