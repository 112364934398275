import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { checkUsername } from '../helpers/auth';
import { SET_USER_ID, SET_USERNAME, SET_SPINNER_VISIBLE } from '../reducer';
import { SET_CODE, SET_STUDENT_RESET } from '../reducer';
import { resetPassword, changePasswordWithToken, login, addToAccount, pushConcurrent, signalConcurrent, validCredentialsRouteToLandingPage } from '../helpers/auth';
import { Context } from '../Store';

const titleClassNames = ['bold', 'center', 'wcag-h2-mimic-h3'];

const INVALID_CREDENTIALS = 'invalid_credentials';
const API_CALL_ERROR = 'api_call_error';

function UsernamePassword() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [usernameField, setUsernameField] = useState('');
  const [passwordField, setPasswordField] = useState('');
  const [status, setStatus] = useState('');
  const [state, setState] = useState({ 
    touched: false, 
    err: '', 
    view: 'username', 
    status: '', 
    username: '',
    password: '' 
  });
  const [addSuccess, setAddSuccess] = useState(false);

  // Focus input field on load.
  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const usernameRef = useRef();
  const passwordRef = useRef();
  const passwordIconRef = useRef();

  
  async function registrationLogin(payload) {
    const loginResult = await login(payload.email, payload.pw)
    
    if (!loginResult) {
        setStatus(API_CALL_ERROR);
        dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
        return;
    }

    const res = loginResult;

    if (res.concurrent_session_ids) {
        signalConcurrent(res);
    }

    if (!res.status) {
        validCredentialsRouteToLandingPage(res);
    } else {
        setStatus(INVALID_CREDENTIALS);
    }
}

async function handleLogin(e) {
    e.preventDefault();
    dispatch({ type: SET_SPINNER_VISIBLE, payload: true });
    if (!usernameField || !passwordField) {
        var err = <span>Error: Enter both the username and password.</span>;
        setState({ ...state, focus: true, valid: false, err });
    } else {
        var payload = { email: usernameField, pw: passwordField, wrap_output: true };

        // Not sure why / if this condition is needed.
        if (appState.addToAccount && !addSuccess) {
            console.log('====> Add to account');
            //await registrationAddToAccount({ ...payload, token: appState.code });
        } 
        
        // This is what we'll be using.
        else {
            console.log('====> Logging in with', payload);
            await registrationLogin(payload);
            console.log('====> Attempted Login in with', payload);
        }
    }
    dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
}


  function handleUseACode() {
    dispatch({ type: SET_STUDENT_RESET, payload: false });
    dispatch({ type: SET_CODE, payload: '' });
    history.push('/code');
  }



  // Maintain username as it's entered
  function updateUsername(e) {
    var username = e.target.value;
    setUsernameField(username);
    setStatus('');
  }

  function updatePassword(e) {
    var password = e.target.value;
    setPasswordField(password);
    setStatus('');
}

  function toggleShowPassword() {
    const field = passwordRef.current;
    const passwordIcon = passwordIconRef.current;

    if (field.type === 'password') {
        passwordRef.current.type = 'text';
        passwordIcon.classList.remove('glyphicon-eye-open');
        passwordIcon.classList.add('glyphicon-eye-close');
    } else {
        passwordRef.current.type = 'password';
        passwordIcon.classList.remove('glyphicon-eye-close');
        passwordIcon.classList.add('glyphicon-eye-open');
    }
    
  }

  /*
  function handleSubmit(e) {
		e.preventDefault();
    console.log('====> handleSubmit');
	}
*/
  const errorClass = (status === INVALID_CREDENTIALS || status === API_CALL_ERROR) ? 'show-error' : 'hide-error';
  return (
      <main className="username-password">
        <form>
        <div className="form-group">
        <h2 className={titleClassNames.join(' ')}>Engage &middot; Collaborate &middot; Learn</h2>

        <div>
            <label className="font-weight-normal" htmlFor="username">Username:</label>
            <input ref={usernameRef} value={usernameField} onChange={updateUsername} name="username" type="text" className="form-control border-width-1px" id="username" placeholder="Enter email or username" />
        </div>

        <div style={{marginTop: "10px"}}>
            <label className="font-weight-normal" htmlFor="password">Password:</label>
            <input
            ref={passwordRef}
            value={passwordField}
            onChange={updatePassword}
            name="password"
            type="password"
            className="form-control border-width-1px"
            id="password"
            placeholder="Enter password"
            />
        </div>
        <span ref={passwordIconRef} onClick={toggleShowPassword} className="input-valid pull-right glyphicon glyphicon-eye-open"></span>

        <div style={{margin: "10px 0"}}>
          <h4 className={`wcag-err ${errorClass}`}>We didn't recognize that username or password. Try again.</h4>
        </div>

        {/* !state.focus && !state.valid && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> */}
        {/* state.valid && state.username && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> */}

        <div className="text-center">
          <button type="submit" onClick={handleLogin} className="btn accessible-btn-warning">Log in</button>
          <button type="button" onClick={handleUseACode} className="btn accessible-btn-default">Use a code</button>

          <div className="fix-bottom">
            <Link to="/forgot-password">Forgot password</Link>
        </div>

        </div>

      </div>
        </form>
      </main>
  );
}

export default withRouter(UsernamePassword);
