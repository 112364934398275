import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import Store from './Store';
import { Context } from './Store';
import { isDBTS, isSample } from './helpers/config';

import Main from './main/auth.main';
import UsernamePassword from './username-password/auth.username-password';
import Code from './code/auth.code';
import CodeConfirm from './code/auth.code.confirm';
import Email from './email/auth.email';
import CreateStudent from './create/auth.create.student';
import CreateTeacher from './create/auth.create.teacher';
import SecurityCreate from './security/auth.security.create';
import Security from './security/auth.security';
import Completed from './completed/auth.completed';
import Password from './password/auth.password';
import PasswordReset from './password-reset-form/password-reset'; // For new Password Reset. 11951
import ForgotPassword from './password/auth.forgot-password';
import ForgotPasswordResponse from './password/auth.forgot-password-response';
import Reset from './reset/auth.reset';
import Trial from './trial/auth.trial';
import Spinner from './spinner/spinner';
import pl_logo from './assets/images/pl-logo.svg';
import dbts_logo from './assets/images/dbtsdigital_logo.svg';
import sample_logo from './assets/images/sample-logo.svg';
import './assets/scss/styles.scss';

// Added to perform OHW detection. This is to deal with a search engine issue in 9412.
// https://perfectionlearning.sifterapp.com/issues/9412#comment_16759329
function isOHW() {
  let result = window.location.hostname === 'ohw.perfectionlearning.com';
  return result;
}

// Add Robots noindex tag as a possible deterrent for search engine indexing.
// https://perfectionlearning.sifterapp.com/issues/9412#comment_16759329
function addMetaTagNoIndex() {
  let head = document.getElementsByTagName('head')[0];
  let meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex';
  head.appendChild(meta);
}

function renderLogo(theme) {
    let pageTitle = 'Perfection Next - Sign In';
    // If this is ohw.perfectionlearning.com, omit "Perfection Next" from the title. 9412.
    if (isOHW()) {
      pageTitle = 'Sign In';
      addMetaTagNoIndex();
    }
    let logo = pl_logo;
    let imgAlt = 'Perfection Next';
    let headerClass = 'panel-heading panel-heading-mathx';
    let logoImgClass = 'img-responsive mathx-logo';
    // Add in ability to display appropriate for dbts digital.
    // https://trello.com/c/mIiwoxsD/1264-add-dbts-support-to-new-user-interface#comment-5ed6bd5d4d63b8094af48de4
    if (theme === 'dbts') {
      pageTitle = 'dBTS Engage - Sign In';
      logo = dbts_logo;
      imgAlt = 'deBono Thinking System';
      headerClass = 'panel-heading panel-heading-db';
      logoImgClass = 'img-responsive db-logo';
    } else if (theme === 'sample') {
      pageTitle = 'Perfection Learning - Sign In';
      logo = sample_logo;
      imgAlt = 'Perfection Learning';
    }
    document.title = pageTitle;

    return <div className={headerClass}><h1 style={{ marginTop:0 }}><img src={logo} className={logoImgClass} alt={imgAlt}/></h1></div>
}

// Added to accommodate dBTS background and logo. Could be used more broadly.
// https://trello.com/c/mIiwoxsD/1264-add-dbts-support-to-new-user-interface#comment-5ed6bd5d4d63b8094af48de4
function getTheme() {
    var theme = {};
    if (isDBTS) {
        theme.logo = renderLogo('dbts');
        theme.wrapperClass = 'db-background';
    } else if (isSample) {
        theme.logo = renderLogo('sample');
        theme.wrapperClass = 'sample-background';
    } else {
        theme.logo = renderLogo('default');
        theme.wrapperClass = 'pl-background';
    }
    return theme;
}

// For 18838
function getCopyrightYear() {
    const copyrightYear = new Date().getFullYear();
    return copyrightYear;
}

function Login() {
  const [ appState, dispatch ] = useContext(Context);
  const theme = getTheme();
  const copyrightYear = getCopyrightYear();

  var code = (
    <div id="login">
      <div className={'login-wrapper ' + theme.wrapperClass}>
        <div className="panel panel-position login-card-container">
          {theme.logo}
          <div className="panel-body">
            <div className="vertical-center-container">
              <div className="vertical-center-form">
                <Route exact path={"/"} component={UsernamePassword}/>
                <Route path={"/logout"} component={UsernamePassword}/>
                <Route path={"/main"} component={UsernamePassword}/>
                <Route exact path={"/code"} component={Code}/>
                <Route path={"/code/confirm"} component={CodeConfirm}/>
                <Route path={"/user/email"} component={Email}/>
                <Route path={"/user/create-student"} component={CreateStudent}/>
                <Route path={"/user/create-teacher"} component={CreateTeacher}/>
                <Route path={"/user/security/create"} component={SecurityCreate}/>
                <Route path={"/user/complete"} component={Completed}/>
                <Route path={"/user/username"} component={UsernamePassword}/>
                <Route path={"/login/password"} component={Password}/>
                <Route path={"/forgot-password"} component={ForgotPassword}/>
                <Route path={"/forgot-password-response"} component={ForgotPasswordResponse}/>
                <Route path={"/user/reset"} component={Reset}/>
                <Route path={"/password-reset"} component={PasswordReset}/>
                <Route path={"/trial"} component={Trial}/>
              </div>
            </div>
          </div>
        </div>
        <div className={'footer'}>
          <div className={'privacy-terms'}>
            <div>&copy; {copyrightYear} Perfection Learning. All Rights Reserved.</div>
            <div><a target="_blank" href="https://www.perfectionnext.com/privacy-policy.html">Privacy Policy</a>&nbsp;|&nbsp;
            <a target="_blank" href="https://www.perfectionnext.com/terms-and-conditions.html">Terms &amp; Conditions</a>&nbsp;|&nbsp;
            <a target="_blank" href="https://support.perfectionnext.com">Support Site</a></div>
          </div>
        </div>
      </div>
      <Spinner visible={appState.spinnerVisible}/>
    </div>
  );

  return code;
}

function App() {

  return (
    <Store>
      <Login />
    </Store>
  );
}

export default App;
